





import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import CreateWallet from "@/components/CreateWalletWorkflow/CreateWallet.vue";

@Component({
  components: {
    CreateWallet,
  },
  metaInfo: () => {
    const description =
      "Start building Dijetal Wealth Portfolio today. Create a wallet with Dijets to get started.";
    return {
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description,
        },
        {
          vmid: "og:description",
          name: "description",
          content: description,
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: "Create | Dijets Wallet",
        },
      ],
      title: "Create",
    };
  },
})
export default class Create extends Vue {}
