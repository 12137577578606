


















































































































import "reflect-metadata";
import { Vue, Component } from "vue-property-decorator";
import HomeNextStep from "./HomeNextStep.vue";

@Component({
  name: "homekyc",
  components: { HomeNextStep },
})
export default class Homekyc extends Vue {
  starting = false;
  mounted() {
    this.starting = false;
    localStorage.removeItem("Email");
    localStorage.removeItem("Phone");
  }

  goToStepper() {
    // go to stepper view whitout router
    this.starting = true;
  }
}
