
























import "reflect-metadata";
import { Component, Prop, Vue } from "vue-property-decorator";
import Modal from "@/components/modals/Modal.vue";
import { WalletType } from "@avalabs/avalanche-wallet-sdk";
import WalletCreated from "./WalletCreated.vue";

@Component({
  components: {
    Modal,
    WalletCreated,
  },
})
export default class KycVerification extends Vue {
  @Prop() walle!: WalletType;
  @Prop() verificationCompleted!: boolean;
  walletCreated: boolean = this.verificationCompleted ? true : false;
  doneWalletCreation() {
    this.walletCreated = true;
  }
}
