







































































































































































































































































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";
import FrontTip from "@/components/misc/FrontTip.vue";
import MidTip from "@/components/misc/MidTip.vue";
import ThirdTip from "@/components/misc/ThirdTip.vue";
import ToS from "@/components/misc/ToS.vue";

@Component({
  name: "home",
  components: { ToS, FrontTip, MidTip, ThirdTip },
})
export default class Home extends Vue {}
