import { AvaNetwork } from "@/js/AvaNetwork";

export const MainnetConfig = new AvaNetwork(
  "Mainnet",
  "https://dijetsnet.uksouth.cloudapp.azure.com:443",
  1,
  "https://djtexplorer.uksouth.cloudapp.azure.com/",
  "https://explorer.dijets.io",
  true
);

export const TestnetConfig = new AvaNetwork(
  "Local",
  "http://127.0.0.1:3000",
  12345,
  "http://127.0.0.1:3001",
  "",
  true
);
