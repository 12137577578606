







import { Vue, Component } from "vue-property-decorator";
import KYCContent from "@/components/misc/KYCContent.vue";
@Component({
  components: { KYCContent },
})
export default class Kycfaq extends Vue {
  get isCore() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("core")) {
      return true;
    }
    return false;
  }
}
