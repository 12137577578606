import { Module } from "vuex";
import { RootState } from "@/store/types";
import { NetworkState } from "@/store/modules/network/types";

import { ava, avm, bintools, cChain, infoApi, pChain } from "@/AVA";
import { AvaNetwork } from "@/js/AvaNetwork";
import { explorer_api } from "@/explorer_api";
import { BN } from "avalanche";
import { getPreferredHRP } from "avalanche/dist/utils";
import router from "@/router";
import { web3 } from "@/evm";
import { setSocketNetwork } from "../../../providers";
import {
  getConfigFromUrl,
  setNetworkAsync,
} from "@avalabs/avalanche-wallet-sdk";
import {
  MainnetConfig,
  TestnetConfig,
} from "@/store/modules/network/constants";
const network_module: Module<NetworkState, RootState> = {
  namespaced: true,
  state: {
    status: "disconnected", // disconnected | connecting | connected
    networks: [],
    networksCustom: [],
    selectedNetwork: null,
    txFee: new BN(0),
  },
  mutations: {
    addNetwork(state, net: AvaNetwork) {
      state.networks.push(net);
    },
  },
  getters: {
    allNetworks(state) {
      return state.networks.concat(state.networksCustom);
    },
  },
  actions: {
    addCustomNetwork({ state, dispatch }, net: AvaNetwork) {
      // Check if network alerady exists
      const networks = state.networksCustom;
      // Do not add if there is a network already with the same url
      for (let i = 0; i < networks.length; i++) {
        const url = networks[i].url;
        if (net.url === url) {
          return;
        }
      }
      state.networksCustom.push(net);
      dispatch("save");
    },

    async removeCustomNetwork({ state, dispatch }, net: AvaNetwork) {
      const index = state.networksCustom.indexOf(net);
      state.networksCustom.splice(index, 1);
      await dispatch("save");
    },
    saveSelectedNetwork({ state }) {
      const data = JSON.stringify(state.selectedNetwork?.url);
      localStorage.setItem("network_selected", data);
    },
    async loadSelectedNetwork({ dispatch, getters }): Promise<boolean> {
      const data = localStorage.getItem("network_selected");
      if (!data) return false;
      try {
        // let net: AvaNetwork = JSON.parse(data);
        const nets: AvaNetwork[] = getters.allNetworks;

        for (let i = 0; i < nets.length; i++) {
          const net = nets[i];
          if (JSON.stringify(net.url) === data) {
            dispatch("setNetwork", net);
            return true;
          }
        }
        return false;
      } catch (e) {
        return false;
      }
    },

    // Save custom networks to local storage
    save({ state }) {
      const data = JSON.stringify(state.networksCustom);
      localStorage.setItem("networks", data);
    },
    // Load custom networks from local storage
    load({ dispatch }) {
      const data = localStorage.getItem("networks");

      if (data) {
        const networks: AvaNetwork[] = JSON.parse(data);

        networks.forEach((n) => {
          const newCustom = new AvaNetwork(
            n.name,
            n.url,
            //@ts-ignore
            parseInt(n.networkId),
            n.explorerUrl,
            n.explorerSiteUrl,
            n.readonly
          );
          dispatch("addCustomNetwork", newCustom);
        });
      }
    },
    async setNetwork({ state, dispatch, commit, rootState }, net: AvaNetwork) {
      state.status = "connecting";

      // Chose if the network should use credentials
      await net.updateCredentials();
      ava.setRequestConfig("withCredentials", net.withCredentials);
      ava.setAddress(net.ip, net.port, net.protocol);
      ava.setNetworkID(net.networkId);

      // Reset transaction history
      commit("History/clear", null, { root: true });

      // Query the network to get network id
      const chainIdX = await infoApi.getBlockchainID("V");
      const chainIdP = await infoApi.getBlockchainID("M");
      const chainIdC = await infoApi.getBlockchainID("U");

      avm.refreshBlockchainID(chainIdX);
      avm.setBlockchainAlias("V");
      pChain.refreshBlockchainID(chainIdP);
      pChain.setBlockchainAlias("M");
      cChain.refreshBlockchainID(chainIdC);
      cChain.setBlockchainAlias("U");

      avm.getDJTXAssetID(true);
      pChain.getDJTXAssetID(true);
      cChain.getDJTXAssetID(true);

      state.selectedNetwork = net;
      dispatch("saveSelectedNetwork");

      // Update explorer api
      explorer_api.defaults.baseURL = net.explorerUrl;

      // Set web3 Network Settings
      const web3Provider = `${net.protocol}://${net.ip}:${net.port}/ext/bc/U/rpc`;
      web3.setProvider(web3Provider);

      // Set socket connections
      setSocketNetwork(net);

      commit("Assets/removeAllAssets", null, { root: true });
      await dispatch("Assets/updateAvaAsset", null, { root: true });

      // If authenticated
      if (rootState.isAuth) {
        // Go back to wallet page
        router.replace("/wallet");
        for (let i = 0; i < rootState.wallets.length; i++) {
          const w = rootState.wallets[i];
          w.onnetworkchange();
        }
      }

      await dispatch("Assets/onNetworkChange", net, { root: true });
      dispatch("Assets/updateUTXOs", null, { root: true });
      dispatch("Platform/update", null, { root: true });
      dispatch("Platform/updateMinStakeAmount", null, { root: true });
      dispatch("updateTxFee");
      // Update tx history
      dispatch("History/updateTransactionHistory", null, { root: true });

      // Set the SDK Network
      const sdkNetConf = await getConfigFromUrl(net.getFullURL());
      await setNetworkAsync({
        ...sdkNetConf,
        explorerURL: net.explorerUrl,
        explorerSiteURL: net.explorerSiteUrl,
      });
      // state.isConnected = true;
      state.status = "connected";
      return true;
    },

    async updateTxFee({ state }) {
      const txFee = await infoApi.getTxFee();
      state.txFee = txFee.txFee;
      avm.setTxFee(txFee.txFee);
    },

    async init({ state, commit, dispatch }) {
      // Load custom networks if any
      try {
        await dispatch("load");
      } catch (e) {
        console.error(e);
      }

      commit("addNetwork", MainnetConfig);
      commit("addNetwork", TestnetConfig);

      try {
        const isSet = await dispatch("loadSelectedNetwork");
        if (!isSet) {
          await dispatch("setNetwork", state.networks[0]);
        }
        return true;
      } catch (e) {
        console.log(e);
        state.status = "disconnected";
      }
    },
  },
};

export default network_module;
